<template>
  <div>
    <div class="main-box">
      <a-form>
        <a-form-item label="公众号头像" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-upload
            name="avatar"
            listType="picture-card"
            class="avatar-uploader"
            :showUploadList="false"
          >
            <LImg v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="公众号名称" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input />
        </a-form-item>
        <a-form-item label="AppID(应用ID)" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingOrganization',

  data() {
    return {
      imageUrl: '',
      loading: false,

      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      let { searchData } = this
      let res = await this.$store.dispatch('weixinSetingMaterialAction', { data: this.searchParams })
      this.list = res.items
    },
  }
}
</script>
