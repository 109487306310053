<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>客户姓名/手机号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.fuzzy" placeholder="客户姓名/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="课程顾问" >
                      <a-select allowClear v-model="searchData.distribute_id" placeholder="请选择">
                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="咨询状态" >
                      <a-select allowClear v-model="searchData.is_deal" placeholder="请选择">
                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="跟进日期" >
                      <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.created_date" :placeholder="['开始时间', '结束时间']"/>
                    </a-form-item>
                    <a-form-item class="block-line" label="所属校区" >
                      <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="来源渠道" >
                      <a-tree-select
                          v-model="searchData.channel_id"
                          :tree-data="parentChannels"
                          tree-checkable
                          allowClear
                          treeNodeFilterProp="title"
                          :maxTagCount='1'
                          :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                          :show-checked-strategy="SHOW_ALL"
                          placeholder="请选择来源渠道"
                          @change='onChangeCascader'
                      />
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1400 }">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '客户姓名', width: '100px', dataIndex: 'realname', key: 'name', fixed: 'left' },
  { title: '所属校区', dataIndex: 'studio_id', key: '1' },
  { title: '课程顾问', dataIndex: 'distribute_id', key: '2' },
  { title: '意向科目', dataIndex: 'course_cate', key: '3' },
  { title: '意向程度', dataIndex: 'intention_level', key: '4' },
  { title: '来源渠道', dataIndex: 'channel_id', key: '5' },
  { title: '咨询状态', dataIndex: 'is_deal', key: '6' },
  { title: '跟进方式', dataIndex: 'contact_type', key: '7' },
  { title: '跟进内容', dataIndex: 'log', key: '8' },
  { title: '到访时间', dataIndex: 'date_time', key: '9' },
  { title: '回访时间', dataIndex: 'next_time', key: '10' },
  { title: '记录时间', dataIndex: 'created_at', key: '11' },
]

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      dealStatus: C_ITEMS.dealStatus,
      studios: [],
      consultants: [],
      channelsId: [],
      parentChannels:[],
      searchData: {},

      visible: false
    }
  },
  mixins: [ranges,tableMixins],
  created() {
    this.getStudio()
    this.getConsultant()
    this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getSourceChannel(parent_id) {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getList() {
      
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('clientFollowLogAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
    },
  }
}
</script>
