<template>
  <div>
    <div class="main-box">
      <a-form >
        <a-form-item label="商户号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input />
        </a-form-item>
        <a-form-item label="商户支付密钥" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input />
        </a-form-item>
        <a-form-item label="退款授权KEY" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input />
        </a-form-item>
        <a-form-item label="退款授权CERT" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingOrganization',
  data() {
    return {
      imageUrl: '',
      loading: false,

      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      let { searchData } = this
      let res = await this.$store.dispatch('weixinPaymentAction', { data: this.searchParams })
      this.list = res.items
    },
  }
}
</script>
