<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>微信管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <!-- <a-tab-pane key="1">
          <span slot="tab">微信设置<a-badge :count="0" /></span>
          <wxsz v-if="activeKey==='1'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="2">
          <span slot="tab">自动回复<a-badge :count="0" /></span>
          <zdhf v-if="activeKey==='2'"/>
        </a-tab-pane> -->
        <a-tab-pane key="1">
          <span slot="tab">支付设置<a-badge :count="0" /></span>
          <zfsz v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">模板消息<a-badge :count="0" /></span>
          <mbxx v-if="activeKey==='2'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import wxsz from './wxsz/index'
import zdhf from './zdhf/index'
import zfsz from './zfsz/index'
import mbxx from './mbxx/index'

export default {
  components: {
    wxsz,
    zdhf,
    zfsz,
    mbxx,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

